import { C } from '../../constants';

const initialState = { //define initial state - an empty form

  total: 0,
  bqLoad: 0,
  scoreAll: 0,
  flags: 0,
  fat: 0,
  archiving: 0,
  saveAll: 0,

};

export default (state = initialState, action) => {
  switch (action.type) {

    case C.SET_TOTAL_ASSESSMENTS:
        return Object.assign({},state,{
            total : action.total
        });

    case C.SET_RETRO_SCORING_COUNT:
        return Object.assign({}, state, {
            scoreAll: action.total
        })

    case C.SET_BQ_LOAD_COUNT:
        return Object.assign({}, state, {
            bqLoad: action.total
        })

    case C.SET_SAVE_ALL_COUNT:
        return Object.assign({}, state, {
          saveAll: action.total
        })

    case C.SET_FLAG_REMOVE_COUNT:
      return Object.assign({}, state, {
            flags: action.total
        })

    case C.SET_TRIM_THE_FAT_COUNT:
      return Object.assign({}, state, {
            fat: action.total
        })

    case C.SET_ARCHIVING_COUNT:
      return Object.assign({}, state, {
            archiving: action.total
        })

      default:
        return state;
  }
}